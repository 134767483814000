import * as React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const query = graphql`
  query {
    allStrapiSupportPage {
      edges {
        node {
          id
          title
          slug
          category
          sub_category
          strapiId
        }
      }
    }
  }
`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <StaticQuery
      query={query}
      render={data => (
        <ul>
          {data.allStrapiSupportPage.edges.map(supportpage => (
            <li key={supportpage.node.strapiId}>
              <a href={supportpage.node.slug}>{supportpage.node.title}</a>
            </li>
          ))}
        </ul>
      )}
    />

    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p>
  </Layout>
)

export default IndexPage
